<template>
    <layout>
        <were-changes-alert :were-changes="wereChanges" @save="onSave" />
        <page-header :title="title" :items="breadcrumbsItems" />

        <div class="mb-3">
            <button
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
                @click="onSave"
            >
                <i class="fas fa-save mr-1" />
                Save
            </button>
        </div>

        <div class="card-box">
            <media-chooser
                v-model="extension.icon"
                label="Icon"
                class="col-xl-12"
            />

            <div class="form-group col-12">
                <label>Name <span class="text-danger">*</span></label>
                <input
                    v-model="extension.name"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.extension.name.$error
                    }"
                />
            </div>

            <div class="form-group col-12">
                <label>Description</label>
                <input
                    v-model="extension.description"
                    type="text"
                    class="form-control"
                />
            </div>

            <div class="form-group col-12">
                <label for="price">
                    Price <span class="text-danger">*</span>
                </label>
                <currency-input
                    id="price"
                    v-model.number="extension.price"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': $v.extension.price.$error
                    }"
                    placeholder="Enter amount"
                />
            </div>

            <div class="form-group col-12">
                <b-form-checkbox v-model="extension.show_in_summary" switch>
                    Show In Summary
                </b-form-checkbox>
            </div>

            <div class="form-group col-12">
                <b-form-checkbox v-model="extension.learn_more_enabled" switch>
                    Show Learn More
                </b-form-checkbox>
            </div>

            <div class="form-group col-12">
                <label for="learn_more_text">
                    Learn More Text
                </label>
                <textarea
                    id="learn_more_text"
                    v-model="extension.learn_more_text"
                    class="form-control"
                    rows="3"
                />
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import { required } from 'vuelidate/lib/validators';
import MediaChooser from '@components/media/MediaChooser';
import CurrencyInput from '@components/elements/CurrencyInput';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    page: {
        title: 'Extension Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        MediaChooser,
        CurrencyInput,
        WereChangesAlert
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'product-extension-details-add') {
                vm.$toaster('Extension has been created!');
            }
        });
    },

    props: {
        productId: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            title: 'New Extension',
            extension: {
                icon: {}
            },
            initValueStringify: '',
            editMode: false
        };
    },

    validations: {
        extension: {
            name: {
                required
            },
            price: {
                required
            }
        }
    },

    computed: {
        wereChanges() {
            if (!this.extension || !this.editMode) {
                return false;
            }

            return JSON.stringify(this.extension) !== this.initValueStringify;
        },

        breadcrumbsItems() {
            return [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Product List',
                    to: '/products'
                },
                {
                    text: 'Product Details',
                    to: `/products/${this.productId}/edit`
                },
                {
                    text: this.id ? 'Extension Details' : 'New Extension'
                }
            ];
        }
    },

    async mounted() {
        if (this.id) {
            this.editMode = true;
            this.title = 'Extension Details';

            await this.fetchProductExtension();

            this.initValueStringify = JSON.stringify(this.extension);
        }
    },

    methods: {
        ...mapActions({
            getProductExtension: 'productExtensions/show',
            createProductExtension: 'productExtensions/store',
            updateProductExtension: 'productExtensions/update'
        }),

        async fetchProductExtension() {
            try {
                this.extension = await this.getProductExtension(this.id);

                this.extension.price /= 100;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onSave() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                const extension = {
                    ...this.extension,
                    price: Math.round(this.extension.price * 100)
                };

                if (!this.editMode) {
                    const createdExtension = await this.createProductExtension({
                        productId: this.productId,
                        extension
                    });

                    this.$router.push(
                        `/products/${this.productId}/extensions/${
                            createdExtension.id
                        }/edit`
                    );
                } else {
                    await this.updateProductExtension({
                        id: this.id,
                        extension
                    });

                    this.initValueStringify = JSON.stringify(this.extension);

                    this.$toaster('Extension has been updated!');
                }
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        }
    }
};
</script>
